<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../../assets/img/home/banner.png" alt="" />
    </div>
    <!-- <div class="breadcrumb_box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/enterpriseService' }"
          >基础服务</el-breadcrumb-item
        >
        <el-breadcrumb-item>知识产权服务</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->

    <div class="container_title container_title_first">服务优势</div>
    <div class="serviceSup">
      <div class="serviceSup_item">
        <div class="supitem_left">
          <img
            src="../../../assets/img/home/learningService/fwys1.png"
            style="width: 60%; height: 60%"
            alt=""
          />
        </div>
        <div class="supitem_right">
          <div class="supitem_right_title">透明收费，性价比高</div>
          <div class="supitem_right_dsc">按服务项目收费</div>
          <div class="supitem_right_dsc">签完合同后无额外费用</div>
        </div>
      </div>

      <div class="serviceSup_item">
        <div class="supitem_left">
          <img
            src="../../../assets/img/home/learningService/fwys2.png"
            style="width: 60%; height: 60%"
            alt=""
          />
        </div>
        <div class="supitem_right">
          <div class="supitem_right_title">快速响应，急速拿证</div>
          <div class="supitem_right_dsc">专业客服团队，一对一服务</div>
          <div class="supitem_right_dsc">高级顾问师服务团队</div>
        </div>
      </div>

      <div class="serviceSup_item">
        <div class="supitem_left">
          <img
            src="../../../assets/img/home/learningService/fwys3.png"
            style="width: 60%; height: 60%"
            alt=""
          />
        </div>
        <div class="supitem_right">
          <div class="supitem_right_title">有保障，通过</div>
          <div class="supitem_right_dsc">国际机构合作，过硬关系网</div>
          <div class="supitem_right_dsc">10年行业经验</div>
        </div>
      </div>
    </div>

    <div class="hotService">
      <div class="container_title">热门服务</div>
      <div class="hotService_box">
        <div class="hot_left">
          <div class="hot_left_top">
            <div class="supitem_left">
              <img
                src="../../../assets/img/home/learningService/fwys2.png"
                style="width: 60%; height: 60%"
                alt=""
              />
            </div>
            <div class="supitem_right">
              <div class="supitem_right_title">ISO认证</div>
              <div class="supitem_right_dsc">提升公司竞争力</div>
            </div>
          </div>

          <div class="supitem_right_dsc">·ISO9001质量管理体系认证</div>
          <div class="supitem_right_dsc">·ISO14001环境管理体系认证</div>
          <div class="supitem_right_dsc">·ISO45001管理体系认证</div>
          <div class="supitem_right_dsc">·ISO27001信息安全管理体系</div>
          <div class="supitem_right_dsc">·ISO22000食品安全管理体系</div>
          <div class="supitem_right_dsc">·IATF16949汽车质量管理体系认证</div>
          <div class="supitem_right_dsc">·ISO20000信息技术服务管理体系</div>
          <div class="supitem_right_dsc">·ISO13485医疗器械质量管理体系</div>
        </div>
        <div class="hot_right">
          <div class="hotright_top">
            <div class="hotrighttop_left">
              <div class="hotrighttop_left_item">
                <div class="hotright_img">
                  <img
                    src="../../../assets/img/home/learningService/rmfu1.png"
                    style="width: 100%; height: 100%"
                    alt=""
                  />
                </div>
                <div class="supitem_right">
                  <div class="supitem_right_title">AAA信用评级</div>
                  <div class="supitem_right_dsc">
                    企业信用认证，招投标加分<br />享受更多优惠政策
                  </div>
                </div>
              </div>
              <div class="hot_btn">了解更多</div>
            </div>

            <div class="hotrighttop_left" style="margin-left: 2%">
              <div class="hotrighttop_left_item">
                <div class="hotright_img">
                  <img
                    src="../../../assets/img/home/learningService/rmfu2.png"
                    style="width: 100%; height: 100%"
                    alt=""
                  />
                </div>
                <div class="supitem_right">
                  <div class="supitem_right_title">服务认证</div>
                  <div class="supitem_right_dsc">
                    参与市场竞争的尖锐利器<br />意度 提升企业能交
                  </div>
                </div>
              </div>
              <div class="hot_btn">了解更多</div>
            </div>
          </div>

          <div class="hotright_bottom">
            <div class="hotrighttop_left_item">
              <div class="hotright_img">
                <img
                  src="../../../assets/img/home/learningService/rmfu3.png"
                  style="width: 100%; height: 100%"
                  alt=""
                />
              </div>
              <div class="supitem_right">
                <div class="supitem_right_title">高企认定</div>
              </div>
            </div>

            <div class="hotrighttop_left_item">
              <div class="hotright_img">
                <img
                  src="../../../assets/img/home/learningService/rmfu4.png"
                  style="width: 100%; height: 100%"
                  alt=""
                />
              </div>
              <div class="supitem_right">
                <div class="supitem_right_title">专精特新</div>
              </div>
            </div>

            <div class="hotrighttop_left_item">
              <div class="hotright_img">
                <img
                  src="../../../assets/img/home/learningService/rmfu5.png"
                  style="width: 100%; height: 100%"
                  alt=""
                />
              </div>
              <div class="supitem_right">
                <div class="supitem_right_title">专利补贴</div>
              </div>
            </div>

            <div class="hotrighttop_left_item">
              <div class="hotright_img">
                <img
                  src="../../../assets/img/home/learningService/rmfu6.png"
                  style="width: 100%; height: 100%"
                  alt=""
                />
              </div>
              <div class="supitem_right">
                <div class="supitem_right_title">项目申报</div>
              </div>
            </div>

            <div class="hotrighttop_left_item">
              <div class="hotright_img">
                <img
                  src="../../../assets/img/home/learningService/rmfu7.png"
                  style="width: 100%; height: 100%"
                  alt=""
                />
              </div>
              <div class="supitem_right">
                <div class="supitem_right_title">商标申请</div>
              </div>
            </div>

            <div class="hotrighttop_left_item">
              <div class="hotright_img">
                <img
                  src="../../../assets/img/home/learningService/rmfu8.png"
                  style="width: 100%; height: 100%"
                  alt=""
                />
              </div>
              <div class="supitem_right">
                <div class="supitem_right_title">专利申请</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="serviceflow" style="color: #fff">
      <div class="container_title">服务流程</div>

      <div class="serviceflow_box">
        <div class="serviceflow_item">
          <div class="serviceflow_item_bg">
            <img
              src="../../../assets/img/home/learningService/lc1.png"
              style="width: 100%; height: 100%"
              alt=""
            />
          </div>
          <div class="supitem_right_title">在线咨询<br />电话沟通</div>
        </div>

        <div class="serviceflow_item">
          <div class="serviceflow_item_bg">
            <img
              src="../../../assets/img/home/learningService/arrow.png"
              style="width: 100%; height: 100%"
              alt=""
            />
          </div>
          <div class="supitem_right_title"></div>
        </div>

        <div class="serviceflow_item">
          <div class="serviceflow_item_bg">
            <img
              src="../../../assets/img/home/learningService/lc2.png"
              style="width: 100%; height: 100%"
              alt=""
            />
          </div>
          <div class="supitem_right_title">寄样样品<br />样品评估</div>
        </div>

        <div class="serviceflow_item">
          <div class="serviceflow_item_bg">
            <img
              src="../../../assets/img/home/learningService/arrow.png"
              style="width: 100%; height: 100%"
              alt=""
            />
          </div>
          <div class="supitem_right_title"></div>
        </div>

        <div class="serviceflow_item">
          <div class="serviceflow_item_bg">
            <img
              src="../../../assets/img/home/learningService/lc3.png"
              style="width: 100%; height: 100%"
              alt=""
            />
          </div>
          <div class="supitem_right_title">合同签订<br />样品检测</div>
        </div>

        <div class="serviceflow_item">
          <div class="serviceflow_item_bg">
            <img
              src="../../../assets/img/home/learningService/arrow.png"
              style="width: 100%; height: 100%"
              alt=""
            />
          </div>
          <div class="supitem_right_title"></div>
        </div>

        <div class="serviceflow_item">
          <div class="serviceflow_item_bg">
            <img
              src="../../../assets/img/home/learningService/lc4.png"
              style="width: 100%; height: 100%"
              alt=""
            />
          </div>
          <div class="supitem_right_title">出具报告</div>
        </div>

        <div class="serviceflow_item">
          <div class="serviceflow_item_bg">
            <img
              src="../../../assets/img/home/learningService/arrow.png"
              style="width: 100%; height: 100%"
              alt=""
            />
          </div>
          <div class="supitem_right_title"></div>
        </div>

        <div class="serviceflow_item">
          <div class="serviceflow_item_bg">
            <img
              src="../../../assets/img/home/learningService/lc5.png"
              style="width: 100%; height: 100%"
              alt=""
            />
          </div>
          <div class="supitem_right_title">售后<br />技术服多</div>
        </div>
      </div>
    </div>

    <div class="process_btn" @click="handleApply">立即办理</div>

    <!-- 一键办理弹出框 -->
    <el-dialog
      title="预留信息"
      :visible.sync="dialogVisible"
      width="25%"
      center
      :before-close="dialogColse"
    >
      <div class="dialog_head">
        <span>我们的客户经理将在1个工作日内与您联系！</span>
      </div>
      <div class="register_phone">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="10px"
          class="demo-ruleForm"
        >
          <el-form-item prop="reserveName">
            <el-input
              prefix-icon="el-icon-user"
              v-model="ruleForm.reserveName"
              placeholder="请输入称呼昵称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="reservePhone">
            <el-input
              prefix-icon="el-icon-mobile-phone"
              v-model="ruleForm.reservePhone"
              maxlength="11"
              placeholder="请输入联系方式"
            ></el-input>
          </el-form-item>
          <el-form-item prop="reserveEntName">
            <el-input
              prefix-icon="el-icon-office-building"
              v-model="ruleForm.reserveEntName"
              placeholder="请输入企业名称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="remark">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2 }"
              v-model="ruleForm.remark"
              placeholder="备注......(200字以内)"
              maxlength="200"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >提交预约</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>

    <el-dialog :visible.sync="dialogSuccess" width="25%" center>
      <div class="success_icon">
        <img src="../../../assets/img/success.png" alt="" />
      </div>
      <div class="success_title">提交成功</div>
      <div class="success_content">
        <p>随后工作人员将与您联系，请保持电话畅通。</p>
        <p>咨询热线：<span>400-666-3369</span></p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addReserve } from "../../../api/reserve";
export default {
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: false,
      registerPhone: "",
      formInline: {
        address: "太原",
        phone: "",
        acctName: "",
      },

      dialogSuccess: false,
      dialogVisible: false,
      registerPhone: "",
      ruleForm: {
        reserveName: "",
        reservePhone: "",
        reserveEntName: "",
        remark: "",
      },
      rules: {
        reserveName: [
          { required: true, message: "请输入预约人名称", trigger: "blur" },
          { min: 2, max: 5, message: "长度在 2 到 5 个字符", trigger: "blur" },
        ],
        reservePhone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    onSubmit() {
      this.dialogVisible = true;
    },
    // 体验信息提交
    handleApply() {
      this.dialogVisible = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.reserveType = "05";
          addReserve(this.ruleForm).then((response) => {
            // if (response.code == 200) {
            //   // this.$modal.msgSuccess("意见办理注册预约成功");
            // }
            this.dialogSuccess = true;
            this.dialogVisible = false;
          });
        }
      });
    },
    dialogColse() {
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false;
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.banner {
  width: 100%;
  height: 300px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.breadcrumb_box {
  width: 1200px;
  padding: 30px 0 0;
  margin: 0 auto;
}

.container {
  padding: 60px;
}

.container_title {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container_title_first {
  padding-top: 60px;
}

.serviceSup {
  width: 70%;
  height: 200px;
  border-radius: 92.5px;
  box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.14);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3% auto;
}

.serviceSup_item {
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.serviceSup_item .supitem_left {
  width: 75px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2%;
  border-radius: 50%;
  border: 3px solid #e5e5e5;
}

.serviceSup_item .supitem_right {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.supitem_right_title {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
}

.supitem_right_dsc {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #949494;
}

.hotService {
  width: 100%;
  height: 600px;
  background-color: #f5f5f5;
  padding-top: 2px;
}

.hotService_box {
  width: 60%;
  height: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto;
}

.hot_left {
  flex: 1;
  height: 89%;
  background-color: #fff;
  margin-right: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.hot_left_top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10%;
}

.hot_btn {
  width: 132px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 28px;
  color: #055afe;
  cursor: pointer;
  margin: 0 auto;
  border: 1px solid #055afe;
}

.hot_right {
  flex: 3;
  height: 100%;
}

.hotright_img {
  width: 87px;
  height: 87px;
}

.hotright_top {
  display: flex;
  height: 45%;
}

.hotrighttop_left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
}

.hotrighttop_left_item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6%;
}

.hotright_bottom {
  height: 40%;
  margin-top: 2%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.hotright_bottom .hotrighttop_left_item {
  width: 33%;
  margin-bottom: 0;
}

.serviceflow {
  width: 100%;
  height: 400px;
  background-color: #055afe;
}

.serviceflow_box {
  width: 80%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.serviceflow_item {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.serviceflow_item .supitem_right_title {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  margin-top: 8%;
}

.serviceflow_item_bg {
}

.serviceflow_item {
  width: 10%;
}

.process_btn {
  width: 132px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 28px;
  background: #055afe;
  cursor: pointer;
  color: #fff;
  margin: 0 auto;
  margin-top: 60px;
}

.el-form {
  text-align: center;
}
.dialog_head {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #055afe;
  margin-bottom: 20px;
}
.success_icon {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.success_icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.success_title {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin: 30px 0;
}

.success_content {
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: #919191;
}
.success_content p span {
  color: #055afe;
}
</style>
